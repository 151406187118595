(function($){

    $(function () {
        /*
        Vendor inits
         */
        picturefill();
        svg4everybody();

        /*
        Utilities
         */
        SVHA.Utility.StateManager.run();

        /*
        Site specific widget intits
         */
        SVHA.Widgets.GlobalWidgets.init();

        this.MainHeader = new SVHA.Widgets.MainHeader();
        this.PrimaryNav = new SVHA.Widgets.PrimaryNav();

        this.FooterAccordion = new SVHA.Widgets.AccordionContent({
            mobileOnly: true,
            selectors: {
                widgetContainer: '#main-footer',
                accordionItem: '.accordion-item',
                btnToggle: '.btn-accordion-trigger',
                contentBlock: '.accordion-content'
            }
        });

        this.ImageSlider = new SVHA.Widgets.ImageSlider();
        this.HospitalSlider = new SVHA.Widgets.HospitalSlider();


        //Highlight straps
        var highlightElements = document.querySelectorAll('.highlight-strap');
        for (var i = 0; i < highlightElements.length; ++i) {

            new SVHA.Widgets.HighlightStrap({
                selectors: {
                    widgetContainer: highlightElements[i],
                }
            })
        }



        //Video Players
        var videoElements = document.querySelectorAll('.video-player');

        for (var i = 0; i < videoElements.length; ++i) {

            new SVHA.Widgets.VideoPlayer({
                selectors: {
                    widgetContainer: videoElements[i],
                }
            })
        }

        //IFrame widgets
        var iFrameElements = document.querySelectorAll('.iframe-widget');

        for (var i = 0; i < iFrameElements.length; ++i) {

            new SVHA.Widgets.iFrameWidget({
                selectors: {
                    widgetContainer: iFrameElements[i],
                }
            })
        }

        this.SpecialistTemplateAccordions = new SVHA.Widgets.AccordionContent({
            firstOpen: false,
            mobileOnly: true,
            resetOnViewportChange: true,
            selectors: {
                widgetContainer: '.specialist-locations',
                accordionItem: '.accordion-item',
                btnToggle: '.btn-accordion-trigger',
                contentBlock: '.accordion-content'
            }
        });

        /*
        Back to top button functionality
        Given its so simple, I've chose not to great a full plugin for it
         */
        $('.btn-back-to-top').click(function () {
            $('html,body').animate({ scrollTop: 0 }, 750);
            return false;
        });


    });

})(jQuery);
