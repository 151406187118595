(function ($, window, undefined) {

    function HospitalSlider(args) {
        this.swiper;
        this.defaults = {
            selectors: {
                widgetContainer: '.hospital-slider',
                swiperContainer: '.swiper-container',
                swiperSlide: '.swiper-slide',
                btnNext: '.swiper-button-next',
                btnPrev: '.swiper-button-prev'
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$swiperContainer = this.parent(this.options.selectors.swiperContainer);
        this.$btnNext = this.parent(this.options.selectors.btnNext);
        this.$btnPrev = this.parent(this.options.selectors.btnPrev);

        this.init()
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    HospitalSlider.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };

    HospitalSlider.prototype.init = function () {
        var self = this;

        this.swiper = new Swiper(this.$swiperContainer, {
            spaceBetween: 30,
            centeredSlides: true,
            centeredSlidesBounds: true,
            loop: false,
            slidesOffsetBefore: 15,
            slidesOffsetAfter: 0,

            breakpoints: {
                992: {
                    freeMode: true,
                    grabCursor: true,
                    slidesPerView: 3,
                    slidesOffsetBefore: 0,
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: this.$btnNext,
                prevEl: this.$btnPrev,
            },
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.HospitalSlider = HospitalSlider;

})(jQuery, window);



