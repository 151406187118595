(function ($, window, undefined) {

    function iFrameWidget(args) {

        this.defaults = {
            videoPlayer:  null,
            heightMobile: null,
            heightDesktop: null,
            srcDefault: null,
            srcDesktop: null,

            selectors: {
                widgetContainer: '.iframe-widget',
            },
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$targetIframe = this.parent('iframe');

        /*
        Set defaults
         */
        this.heightMobile = this.$widgetContainer.attr('data-height-mobile');
        this.heightDesktop = this.$widgetContainer.attr('data-height-desktop');
        this.srcDefault = this.$widgetContainer.attr('data-url-default');
        this.srcDesktop = this.$widgetContainer.attr('data-url-desktop');

        var self = this;

        if(SVHA.Utility.StateManager.ssm.isActive('mobile')){

            self.setHeight(self.heightMobile);

        } else {

            self.setHeight(self.heightDesktop);
            self.srcSwap(self.srcDesktop);
        }

        SVHA.Utility.StateManager.addOnEnter('mobile', function() {
            self.setHeight(self.heightMobile);

            if(self.srcDesktop !== ''){
                self.srcSwap(self.srcDefault);
            }
        });

        SVHA.Utility.StateManager.addOnEnter('desktop', function() {
            self.setHeight(self.heightDesktop);
            self.srcSwap(self.srcDesktop);
        });
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    iFrameWidget.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };

    iFrameWidget.prototype.setHeight = function (height) {
        this.$targetIframe.css('height', height);
    };

    iFrameWidget.prototype.srcSwap = function (srcUrl) {
        if (!srcUrl || srcUrl === ''){
            return;
        }
        this.$targetIframe.attr('src', srcUrl)
    };


    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.iFrameWidget = iFrameWidget;

})(jQuery, window);



