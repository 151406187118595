(function ($, window, undefined) {

    function VideoPlayer(args) {

        this.defaults = {
            videoPlayer:  null,
            selectors: {
                widgetContainer: '.video-player',
                videoElem: '.player-embed',
                btnPlay: '.btn-play-video'
            },

        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$videoElem = this.parent(this.options.selectors.videoElem);
        this.$btnPlay = this.parent(this.options.selectors.btnPlay);



        this.initPlayer();
        this.bindEvents();
     }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    VideoPlayer.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };

    VideoPlayer.prototype.initPlayer = function () {
        this.videoPlayer = new Plyr(this.$videoElem[0], {
            controls:[
                'play',
                'progress',
                'current-time',
                'mute',
                'volume',
                'captions',
                'settings',
                'pip',
                'airplay',
                'fullscreen'
            ]
        });
    };

    VideoPlayer.prototype.playVideo = function (selector) {
        this.$widgetContainer.addClass('video-playing');
        this.videoPlayer.play();
    };

    VideoPlayer.prototype.bindEvents = function () {
        var self = this;

        this.$btnPlay.click(function () {
            self.playVideo();
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.VideoPlayer = VideoPlayer;

})(jQuery, window);



