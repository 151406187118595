(function ($, window, undefined) {

    function _SampleWidget_(args) {

        this.defaults = {
            selectors: {
                widgetContainer: '.foo',
                widgetChild: '.bar'
            },

            timeOut: 500
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$widgetChild = this.parent(this.options.selectors.widgetChild);

        this.bindEvents();
        console.log(this.$widgetContainer);
        console.log('Sample widget ready');
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    _SampleWidget_.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };

    _SampleWidget_.prototype.bindEvents = function () {
        console.log('Sample Widget -- Event binding');
        // console.log(this.$widgetContainer);
        // console.log(this.$widgetChild)

        SVHA.Utility.StateManager.addOnEnter('sm', function() {
            console.log('Sample Module - SM enter')
        });

        SVHA.Utility.StateManager.addOnLeave('sm', function() {
            console.log('Sample Module - SM leave')
        });

        SVHA.Utility.StateManager.addOnEnter('md', function() {
            console.log('Sample Module - MD enter')
        });

        SVHA.Utility.StateManager.addOnLeave('md', function() {
            console.log('Sample Module - MD leave')
        });

        SVHA.Utility.StateManager.addOnEnter('lg', function() {
            console.log('Sample Module - LG enter')
        });

        SVHA.Utility.StateManager.addOnLeave('lg', function() {
            console.log('Sample Module - LG leave')
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets._SampleWidget_ = _SampleWidget_;

})(jQuery, window);



