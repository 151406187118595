(function ($, window, undefined) {

    function ImageSlider(args) {
        this.swiper;
        this.defaults = {
            selectors: {
                widgetContainer: '.image-slider',
                swiperContainer: '.swiper-container',
                swiperSlide: '.swiper-slide',
                imgDescTxt: '.img-desc'
            }
        };

        this.options = $.extend(true, {}, this.defaults, args);

        this.$widgetContainer = $(this.options.selectors.widgetContainer);
        this.$swiperContainer = this.parent(this.options.selectors.swiperContainer);
        this.$imgDescTxt = this.parent(this.options.selectors.imgDescTxt);

        this.init()
    }

    /**
     * Returns a jQuery element that is a child of the parent element
     * @param selector
     * @returns {*|HTMLElement}
     */
    ImageSlider.prototype.parent = function (selector) {
        return $(selector, this.$widgetContainer);
    };

    ImageSlider.prototype.getSlide = function(targetIndex){
        return this.$swiperContainer.find(this.options.selectors.swiperSlide).eq(targetIndex);
    };

    ImageSlider.prototype.setDescTxt = function (txtString) {
        this.$imgDescTxt.append('<p>'+txtString+'</p>');
    };

    ImageSlider.prototype.appendLink = function (txtString, url, isExternal) {
        if (isExternal === 'True'){
            this.$imgDescTxt.append('<a href="'+url+'" target="_blank">'+txtString+'</a>');
        } else {
            this.$imgDescTxt.append('<a href="'+url+'">'+txtString+'</a>');
        }
    };

    ImageSlider.prototype.updateDesc = function(slideIndex){
        var $targetSlide = this.getSlide(slideIndex);
        var imgTxt = $targetSlide.find('img').attr('data-caption');
        var linkUrl = $targetSlide.find('img').attr('data-caption-link');
        var isExternal = $targetSlide.find('img').attr('data-caption-external-link');

        //Clear it first
        this.$imgDescTxt.html('');

        if(linkUrl){
            this.appendLink(imgTxt, linkUrl, isExternal)
        } else {
            this.setDescTxt(imgTxt);
        }
    };

    ImageSlider.prototype.slideStart = function(){
        var activeSlide = this.swiper.activeIndex;
        this.updateDesc(activeSlide)
    };

    ImageSlider.prototype.init = function () {
        var self = this;

        this.updateDesc(0);

        this.swiper = new Swiper(this.$swiperContainer, {
            pagination: {
                el: '.pagination-block',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            on: {
                slideChangeTransitionStart: function(){
                    self.slideStart();
                }
            }
        });
    };

    window.SVHA = window.SVHA || {};
    window.SVHA.Widgets = window.SVHA.Widgets || {};
    window.SVHA.Widgets.ImageSlider = ImageSlider;

})(jQuery, window);



